import {
  DefaultMantineColor,
  MantineProvider as Provider,
  MantineThemeOverride,
} from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ReactNode, useMemo } from 'react';

export type CustomThemes = 'Finalytic' | 'VRPlatform';
export type ExtendedCustomColors = CustomThemes | DefaultMantineColor;

export const MantineProvider = ({
  children,
  themePrimaryColor,
}: {
  children: ReactNode;
  themePrimaryColor: ExtendedCustomColors;
}) => {
  const theme: MantineThemeOverride = useMemo(
    () => ({
      ...defaultTheme,
      primaryColor: themePrimaryColor || theme.primaryColor,
    }),
    [themePrimaryColor]
  );

  return (
    <Provider theme={theme} withGlobalStyles withNormalizeCSS>
      <Notifications />
      {children}
    </Provider>
  );
};

// export const useMTHeme = useMantineTheme;

export const defaultTheme: MantineThemeOverride = {
  colorScheme: 'light',
  primaryColor: 'dark', // Mantine color "dark" from default color scheme
  // radius: {
  //   xs: 0,
  //   sm: 0,
  //   md: 0,
  //   lg: 0,
  //   xl: 0,
  // },
  fontSizes: {
    xs: '0.8125rem',
  },

  colors: {
    purple: [
      '#BF98E7',
      '#AE7CE0',
      '#9E62DA',
      '#904BD5',
      '#8336D0',
      '#772DC1',
      '#6C29AF',
      '#61259D',
      '#57218E',
      '#4F1E80',
    ],

    Finalytic: [
      '#C1C2C5',
      '#A6A7AB',
      '#909296',
      '#5c5f66',
      '#373A40',
      '#2C2E33',
      '#25262b',
      '#1A1B1E',
      '#141517',
      '#101113',
    ],

    VRPlatform: [
      // baseColor: #012F64
      '#E6F1FF',
      '#B8D9FE',
      '#8BC0FE',
      '#5DA8FD',
      '#308FFD',
      '#0377FC',
      '#025FCA',
      '#024797',
      '#012F65',
      '#011832',
    ],

    /*finalytic: [
      '#f1ebfa',
      '#d5c2f0',
      '#b999e6',
      '#9d70db',
      '#8147d1',
      '#672eb8',
      '#50248f',
      '#391966',
      '#220f3d',
      '#0b0514',
    ],*/
    // finalytic: [
    //   '#fafee6',
    //   '#f0fdb4',
    //   '#e7fc82',
    //   '#ddfb50',
    //   '#d3fa1e',
    //   '#b9e105',
    //   '#90af04',
    //   '#677d03',
    //   '#3e4b02',
    //   '#3e4b02',
    // ],
    /*
    Legacy
    finalytic: [
      '#f8ebf9',
      '#e9c4ed',
      '#da9de2',
      '#cb76d6',
      '#bc4eca',
      '#a335b1',
      '#7f2989',
      '#5a1d62',
      '#36123b',
      '#120614',
    ],
    */
  },
};
