import { Auth } from './Auth';
import { RootWrapper } from './RootWrapper';
import { ExtendedCustomColors } from '@finalytic/ui';

export function App({
  themePrimaryColor,
}: {
  themePrimaryColor: ExtendedCustomColors;
}) {
  return (
    <RootWrapper themePrimaryColor={themePrimaryColor}>
      <Auth />
    </RootWrapper>
  );
}
