import { App as RootApp } from '@finalytic/data-ui';

function App() {
  return (
    <div className='App'>
      <RootApp themePrimaryColor='VRPlatform' />
    </div>
  );
}

export default App;
