import {
  ErrorBoundary,
  ExtendedCustomColors,
  MantineProvider,
  QueryParamProvider,
  ReactRouter6Adapter,
} from '@finalytic/ui';
import { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';

export const RootWrapper = ({
  children,
  themePrimaryColor,
}: {
  children: ReactNode;
  themePrimaryColor: ExtendedCustomColors;
}) => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <MantineProvider themePrimaryColor={themePrimaryColor}>
            {children}
          </MantineProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};
